<template>
  <div class="transfer-other-account">
    <div class="info-transference">
      <div class="icon">
        <em class="icon transfer gray-2"></em>
      </div>
      <div class="data">
        <h1 class="text-uppercase label">valor</h1>
        <h1 class="value-1">
          {{
            value
              | currency('R$ ', 2, {
                decimalSeparator: ',',
                thousandsSeparator: '.',
              })
          }}
        </h1>
        <div>
          <h1 class="text-uppercase label pt-3">Taxa de transferência</h1>
          <h1 class="value-1">
            {{
              transferFee
                | currency('R$ ', 2, {
                  decimalSeparator: ',',
                  thousandsSeparator: '.',
                })
            }}
          </h1>

          <h1 class="text-uppercase label pt-3">Valor debitado da sua conta</h1>
          <h1 class="value-1">
            {{
              debitedAmount
                | currency('R$ ', 2, {
                  decimalSeparator: ',',
                  thousandsSeparator: '.',
                })
            }}
          </h1>
        </div>
      </div>
    </div>
    <div class="info-transference">
      <div class="icon">
        <em class="icon-user"></em>
      </div>
      <div class="data">
        <h1 class="text-uppercase label">Favorecido</h1>
        <div>
          <h1 class="label" v-if="holderName">
            <b>{{ bankAccountDetails.holderFullName }}</b>
          </h1>
          <h1 class="label" v-if="splitter">
            <b>{{ splitter.name }}</b>
          </h1>
        </div>
        <div>
          <h1 class="block label" v-if="holderTaxDocument">
            {{ bankAccountDetails.holderTaxDocument }}
          </h1>
          <h1 class="block label" v-if="splitter.bankAccount">
            {{ splitter.bankAccount.taxDocument }}
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppPredictionTransference from '@/components/account/transference/PredictionTransference';
import moment from 'moment';

export default {
  name: 'AppTransferOtherAccount',
  data() {
    return {
      user: null,
      days: {
        0: 'DOM',
        1: 'SEG',
        2: 'TER',
        3: 'QUA',
        4: 'QUI',
        5: 'SEX',
        6: 'SAB',
      },
    };
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    splitter: {
      type: Object,
      default: () => {
        return {};
      },
    },
    estimated: {
      type: String,
      default: '',
    },
    holderName: {
      type: String,
      default: '',
    },
    holderTaxDocument: {
      type: String,
      default: '',
    },
    transferFee: {
      type: Number,
      default: 0,
    },
    debitedAmount: {
      type: Number,
      default: 0,
    },
    typeTransfer: {
      type: String,
      default: '',
    },
    bankAccountDetails: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    date() {
      let dat = new Date();
      return (
        this.days[dat.getDay()] +
        ', ' +
        moment(dat).format('DD/MM/YYYY HH:mm:ss')
      );
    },
  },
};
</script>

<style  >
</style>
