<template>
  <div class="transfer-own-account">
    <div class="info-transference last">
      <div class>
        <em class="icon calendar-outline gray-2"></em>
      </div>
      <div class="data" v-if="prediction !== '' || prediction !== null">
        <h1 class="text-uppercase label">Previsão</h1>
        <h1 class="label">
          <b>{{ prediction }}</b>
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'AppPredictionTransference',
  data() {
    return {
      days: {
        1: 'SEG',
        2: 'TER',
        3: 'QUA',
        4: 'QUI',
        5: 'SEX',
      },
    };
  },
  methods: {
    getNextDay(numberDay, day) {
      switch (numberDay) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4: {
        return day.addDays(1);
      }
      case 6: {
        return day.addDays(2);
      }
      case 5:
        return day.addDays(3);
      }
    },
  },
  computed: {
    prediction() {
      let ret = '';
      let date = new Date();
      let numberDay = date.getDay();
      if (this.days[numberDay] === undefined || numberDay === 5) {
        ret += this.days[1];
      } else {
        ret += this.days[numberDay + 1];
      }
      ret +=
        ',' + moment(this.getNextDay(numberDay, date)).format('DD/MM/YYYY');
      return ret;
    },
  },
};
</script>

<style lang="scss">
.info-transference.last {
  padding-bottom: 1rem;
  padding-top: 2rem;
  .label {
    color: #7d8287 !important;
  }
}
</style>
