<template>
  <div class="ted-container">
    <div class="d-flex form-group">
      <div class="form-group col-2 mb-4">
        <i class="icon icon-small transfer gray"></i>
      </div>
      <div class="form-group col-10 mb-4">
        <label class="small-label">Valor</label>
        <label class="price-label">
          R${{ value.toFixed(2).replace('.', ',') }}
        </label>
      </div>
    </div>
    <div class="d-flex form-group">
      <div class="form-group col-2 mb-4"></div>
      <div class="form-group col-10 mb-4">
        <label class="small-label">Taxa de transferência</label>
        <label class="small-price-label"
          >R${{ fee.toFixed(2).replace('.', ',') }}</label
        >
      </div>
    </div>
    <div class="d-flex form-group">
      <div class="form-group col-2 mb-4"></div>
      <div class="form-group col-10 mb-4">
        <label class="small-label">Valor debitado na sua conta</label>
        <label class="small-price-label">
          <strong>R${{ (fee + value).toFixed(2).replace('.', ',') }}</strong>
        </label>
      </div>
    </div>
    <div class="d-flex form-group">
      <div class="form-group col-2">
        <i class="icon-small icon-wallet gray" />
      </div>
      <div class="form-group col-10">
        <label class="small-label">Destino</label>
        <label class="small-price-label--inline">{{
          destination.fullName
            ? destination.fullName
            : destination.bankAccount.holderName
        }}</label>
      </div>
    </div>
    <div class="d-flex form-group mb-4">
      <div class="form-group col-2"></div>
      <div class="form-group col-10">
        <label class="small-price-label--inline">{{
          destination.bankAccount.taxDocument
        }}</label>
      </div>
    </div>
    <WhiteLabel class="mb-4 offset-8 col-4 mt-4 col-w-full">
      <button class="btn btn-md btn-orange col-12" @click="close">
        Concluir
      </button>
    </WhiteLabel>
  </div>
</template>

<script>
import ModalBase from '@/components/shared/ModalBase';
import AppTrasferOwnAccount from '@/components/account/transference/TransferOwnAccount';
import AppTransferOtherAccount from '@/components/account/transference/TransferOtherAccount';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppModalSuccessTransference',
  components: {
    WhiteLabel,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    typeTransfer: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    fee: {
      type: Number,
      required: true,
    },
    destination: {
      type: Object,
      required: true,
      default: () => {
        return {
          fullName: '',
          bankAccount: {},
        };
      },
    },
  },
  methods: {
    close() {
      this.$bus.$emit('cleanInput');
      this.$bus.$emit('update');
    },
  },
};
</script>

<style lang="scss" scoped>
.content-modal-transfer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 0.5rem;

  .circle {
    padding-bottom: 2rem;
    .icon-check {
      font-size: 96px;
      color: #afdb26;
    }
  }
}
.top {
  background-color: #f5f5f5;
  padding: 2rem;
  text-align: center;
  color: #7d8287;
  font-family: Roboto;
  font-style: normal;
  margin-bottom: 2rem;
  h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
  }

  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
  }
}
.btn-div {
  display: flex;
  justify-content: center;
}
.btn-confirm {
  width: 200px;
  height: 48px;
  bottom: 24px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.15);
  background: linear-gradient(147.43deg, #ff7606 20.2%, #fa3645 100%);
  border-radius: 40px;

  color: white;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.5px;
}
.details-transference,
.details-bank {
  background-color: white !important;
}

.ted-container {
  padding: 1rem;
}
.input-money {
  font-size: 48px;
  max-width: 100%;
  border: none;
  outline: none;
  font-weight: lighter;
  border-bottom: solid thin;
  border-color: #a1a4b1;
}
.mt-lg {
  margin-top: 80px;
}
.small-label {
  display: block;
  text-transform: uppercase;
  color: #7d8287;
  font-size: 12px;
}
.price-label {
  display: block;
  font-size: 30px;
  color: #7d8287;
}
.small-price-label {
  display: block;
  color: #7d8287;

  &--inline {
    display: inline;
  }
}
.icon-small {
  width: 20px;
  height: 20px;
}
label .checkbox-badge {
  margin-top: 5px;
}
.destinations-list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 10px;
    margin: 0 0 20px 0;
    background: #f2f2f2;
    border: solid thin #dbdbdb;
    cursor: pointer;
  }
}
</style>
