<template>
  <div>
    <a @click="selectSellers()">
      <div class="other-user">
        <em class="icon icon-people m-3" />
        <span class="user bold">Estabelecimento</span>
        <em class="arrow-right-icon-new icon gray-2" />
      </div>
    </a>
    <a @click="selectSplitters()">
      <div class="other-user">
        <em class="icon icon-user m-3" />
        <span class="user bold">Splitter</span>
        <em class="arrow-right-icon-new icon gray-2" />
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'AppModalSellerOrSplitter',

  methods: {
    selectSellers(number) {
      this.$emit('sellers-selected', number);
    },
    selectSplitters(number) {
      this.$emit('splitters-selected', number);
    },
  },
};
</script>
