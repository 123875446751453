<template>
  <div>
    <div class="transfer-how-much-transfer">
      <div class="value form-group">
        <span class="currency">R$ &nbsp;</span>
        <money
          v-model="value"
          class="input-value"
          :decimal="','"
          :thousands="'.'"
          :precision="2"
        ></money>
      </div>
      <span class="error-value" v-if="value > getBalance.currentBalance"
        >Saldo Insuficiente</span
      >
      <div
        class="d-flex justify-content-between w-100 mt-1 mb-6 pl-3 pr-3 pb-6"
        v-if="typeTransfer === 0"
      >
        <span>Saldo disponível</span>
        <div class="font-weight-bold">
          <span>{{
            getBalance.currentBalance
              | currency('R$ ', 2, {
                decimalSeparator: ',',
                thousandsSeparator: '.',
              })
          }}</span>
        </div>
      </div>
      <div class="w-100 mt-1 mb-6 pl-3 pr-3 pb-6" v-if="typeTransfer === 1">
        <div class="d-flex justify-content-between">
          <span class="normal d-flex">
            Disponível para transferência
            <em
              class="icon info-icon gray-2"
              data-toggle="tooltip"
              data-html="true"
              data-placement="bottom"
              title="Esse é o valor líquido do seu saldo com o desconto da taxa que será cobrada pela transferência."
            ></em>
          </span>
          <div class="d-flex font-weight-bold">
            <span class="avaiable">{{
              total
                | currency('R$ ', 2, {
                  decimalSeparator: ',',
                  thousandsSeparator: '.',
                })
            }}</span>
          </div>
        </div>
        <div class="d-flex justify-content-between mt-3">
          <span class="normal">Taxa de transferência</span>
          <div class="font-weight-bold">
            <span>{{
              getBalance.transferFee
                | currency('R$ ', 2, {
                  decimalSeparator: ',',
                  thousandsSeparator: '.',
                })
            }}</span>
          </div>
        </div>
      </div>
      <div class="footerModal">
        <div class="information">
          <div class="circle-icon-gray">
            <em class="calendar-outline white icon"></em>
          </div>
          <span v-if="typeTransfer === 0" class="normal"
            >Transferências para outros usuários são realizadas
            instantânteamente</span
          >
          <span v-if="typeTransfer === 1" class="normal">
            Transferências solicitadas
            <strong>após as 13h &nbsp;</strong>(horário de Brasília) serão
            processadas no próximo dia útil
          </span>
        </div>
        <WhiteLabel>
          <button
            @click="confirm"
            type="button"
            class="
              btn btn-orange btn-block btn-gray
              align-self-center
              mt-4
              mb-4
            "
            v-if="value <= getBalance.currentBalance && value > 0"
          >
            Continuar
          </button>
        </WhiteLabel>
      </div>
    </div>
  </div>
</template>
<script>
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import { Money } from 'v-money';
import AppModalConfirmTransferData from '@/components/account/transference/modal/ModalConfirmTransferData';
import Alert from '@/models/Alert';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppModalHowMuchTransfer',
  components: {
    Money,
    WhiteLabel,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    typeTransfer: {
      type: Number,
      default: 0,
    },
    splitter: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      balance: 0,
      value: 0,
      error: null,
      valueFormatting: 0,
      confirmTransferData:
        'confirmTransferData' + Math.random().toString('16').slice(2, 8),
      fee: 0,
      minimum: 0,
      minimumValueInternalTransfer: 0.1,
    };
  },
  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.getBalanceAccount();
    await this.getMinValueTransferApi();
    this.$bus.$emit('spinner-stop');

    this.$bus.$on('cleanInput', (result) => {
      this.cleanInput();
    });

    /* eslint-disable no-undef */
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  },
  methods: {
    async getBalanceAccount() {
      const api = new PaymentAccountsApi();
      this.balance = await api.getBalance();
    },
    async getMinValueTransferApi() {
      const api = new PaymentAccountsApi();
      this.minimum = await api.getMinValueTransfer();
    },
    confirm() {
      if (this.typeTransfer === 0) {
        if (this.value < this.minimumValueInternalTransfer) {
          var alert = new Alert();
          alert.title = 'Ops';
          alert.confirmButtonText = 'OK';
          alert.error = `Valor mínimo para transferência é de R$ ${this.minimumValueInternalTransfer
            .toFixed(2)
            .replace('.', ',')}.`;
          this.$bus.$emit('alert-error', alert);
        } else {
          if (this.typeTransfer === 1) {
            this.$emit('getValue', this.value, this.splitter, 1.1);
          } else this.$emit('getValue', this.value, this.splitter, 2.2);
        }
      } else if (this.typeTransfer === 1) {
        if (this.value < this.minimum.amount) {
          var alert = new Alert();
          alert.title = 'Ops';
          alert.confirmButtonText = 'OK';
          alert.error = `Valor mínimo para transferência é de R$ ${this.minimum.amount
            .toFixed(2)
            .replace('.', ',')}.`;
          this.$bus.$emit('alert-error', alert);
        } else {
          if (this.typeTransfer === 1) {
            this.$emit('getValue', this.value, this.splitter, 1.1);
          } else this.$emit('getValue', this.value, this.splitter, 2.2);
        }
      }
    },
    cleanInput() {
      this.value = 0;
    },
  },
  computed: {
    getBalance() {
      return this.balance;
    },
    validateContinue() {
      return this.error === null && this.value > 0;
    },
    total() {
      return this.balance.currentBalance - this.balance.transferFee;
    },
  },
  watch: {
    value: function (val) {
      this.error = null;
      if (this.value > this.balance.currentBalance) {
        this.error = 'Saldo insuficiente';
      }
    },
    balance: function (val) {
      if (val) {
        this.fee = val.transferFee;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.transfer-how-much-transfer {
  display: flex;
  align-items: center;
  flex-direction: column;

  .icon.info-icon {
    height: 1.2rem;
    margin-left: 1rem;
  }

  .error-value {
    color: red;
  }

  .normal {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #adb5bd;
  }

  .w-100 {
    width: 100%;
  }

  .value {
    display: inline-flex;
    padding: 1rem;

    .currency {
      padding: 1.9rem 0.5rem 0 0;
    }

    .input-value {
      width: 100%;
      color: #adb5bd;
    }
    .currency,
    .input-value {
      font-size: 3rem;
      border: 0;
      border-bottom: 1px solid #f2f2f2;
      line-height: 0;
    }
  }

  .avaiable {
    width: max-content;
  }

  .footerModal {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: #f2f2f2;

    .information {
      display: inline-flex;

      span {
        padding-left: 1rem;
      }
    }

    .circle-icon-gray {
      padding-right: 1rem;
      background-color: #b7b7b7;
      border-radius: 2rem;
      height: 3rem;
      width: 3rem;
      .icon.calendar-outline {
        height: 1.5rem;
        margin-top: 0.7rem;
        margin-left: 0.7rem;
      }
    }
  }
}
</style>
