<template>
  <div>
    <AppDetailsTransference :value="value" :fee="fee" :hasPrediction="false" />
    <AppDetailsBank />
    <AppPredictionTransference />
  </div>
</template>

<script>
import AppDetailsTransference from '@/components/account/transference/DetailsTransference';
import AppDetailsBank from '@/components/account/transference/DetailsBank';
import AppPredictionTransference from '@/components/account/transference/PredictionTransference';
export default {
  name: 'AppTransferOwnAccount',
  components: {
    AppDetailsTransference,
    AppDetailsBank,
    AppPredictionTransference,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    fee: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.details-transference,
.details-bank {
  background-color: white !important;
}
</style>
