<template>
  <div v-if="!switchedToTED">
    <a @click="selectDestino(3)">
      <div class="other-user">
        <em class="icon icon-people m-3" />
        <span class="user bold">TED</span>
        <em class="arrow-right-icon-new icon gray-2" />
      </div>
    </a>
    <a @click="selectDestino(1.7)">
      <div class="other-user">
        <em class="icon icon-user m-3" />
        <span class="user bold">Outro usuário</span>
        <em class="arrow-right-icon-new icon gray-2" />
      </div>
    </a>
  </div>
</template>

<script>
import ModalBase from '@/components/shared/ModalBase';
import AccountApi from '@/services/AccountApi';
import AppModalHowMuchTransfer from '@/components/account/transference/ModalHowMuchTransfer';
import AppModalWhoIstheFavored from '@/components/account/transference/ModalWhoIstheFavored';

export default {
  name: 'AppModalSelectDestination',

  props: {
    id: {
      type: String,
      default: '',
    },
    balance: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    switchedToTED: false,
    bankData: 0,
    modalHowMuchTransferOwnAccount:
      'modalHowMuchTransferOwnAccount' +
      Math.random().toString('16').slice(2, 8),
    modalWhoIstheFavored:
      'modalWhoIstheFavored' + Math.random().toString('16').slice(2, 8),
  }),

  computed: {
    getBankAccount() {
      return this.bankData.bankAccount;
    },
  },

  async created() {
    $(function () {
      $('#tooltipDestination').tooltip();
    });
  },

  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.getBankDataApi();
    this.$bus.$emit('spinner-stop');

    // $(function() {
    //   $('[data-toggle="tooltip"]').tooltip();
    // });
  },

  methods: {
    async getBankDataApi() {
      const api = new AccountApi();
      this.bankData = await api.getBankData();
    },
    selectDestino(number) {
      this.$emit('clicked', number);
    },
  },
};
</script>
