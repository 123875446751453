import CustomApiError from './CustomError';

export default class SplitterTransferError extends CustomApiError {
  toDictionary() {
    return {
      DOESNT_HAVE_MINIMUM_AMOUNT:
        'O titular não possui o valor mínimo para transferência.',
      HOLDER_PAYMENT_ACCOUNT_NOT_FOUND:
        'Conta de pagamento do titular não encontrada.',
      BALANCE_BLOCKED: 'O saldo está bloqueado.',
      SPLITTER_PAYMENT_ACCOUNT_NOT_FOUND:
        'Conta de pagamento do splitter não encontrada.',
      SPLITTER_NOT_FOUND: 'Splitter não encontrado.',
      SPLITTER_BALANCE_BLOCKED: 'Saldo do splitter bloqueado.',
    };
  }
}
