<template>
  <div class="ted-container">
    <section v-if="!showRegisteredDestination && viewControl === 3">
      <div class="form-group col-12 col-w-full mb-4">
        <label for="inputName">Nome completo do favorecido *</label>
        <input
          type="text"
          class="form-control mb-1"
          maxlength="120"
          placeholder="Favorecido"
          v-model="destination.holderName"
        />
        <small class="text-danger" v-if="errorMessages.holderName"
          >Campo obrigatório</small
        >
      </div>
      <div class="form-group col-12 col-w-full mb-4">
        <label for="inputName">Digite os números do CPF ou CNPJ *</label>
        <input
          type="text"
          class="form-control mb-1"
          maxlength="18"
          placeholder="CPF / CNPJ"
          v-mask="['###.###.###-##', '##.###.###/####-##']"
          v-model="destination.holderTaxDocument"
        />
        <small class="text-danger" v-if="errorMessages.holderTaxDocument"
          >Documento inválido</small
        >
      </div>
      <div class="form-group col-12 col-w-full mb-2">
        <WhiteLabel>
          <button
            @click="handleRegisteredDestination"
            class="btn btn-block btn-outline-orange mb-3"
          >
            Favorecidos cadastrados
          </button>
          <button
            @click="handleRegisterDestination"
            class="btn btn-block btn-orange"
          >
            Continuar
          </button>
        </WhiteLabel>
      </div>
    </section>

    <section v-if="showRegisteredDestination">
      <div class="form-group col-12 col-w-full mb-4"></div>
      <div
        class="form-group col-12 col-w-full"
        v-if="destinationsList.length > 0"
      >
        <ul class="destinations-list mb-4">
          <li
            v-for="destinationPerson in destinationsList"
            :key="destinationPerson.id"
          >
            <div
              class="user-favored"
              @click="selectDestination(destinationPerson)"
            >
              <em
                class="icon icon-user pull-right"
                v-if="
                  getSizeDocument(
                    destinationPerson.bankAccountHolderTaxDocument
                  ) === 11
                "
              ></em>
              <em class="company-icon gray-2 icon" v-else></em>
              <div class="data-favored d-flex flex-column">
                <span class="font-weight-bold user-name">{{
                  destinationPerson.bankAccountHolderName
                }}</span>
                <span class="user-document">{{
                  destinationPerson.bankAccountHolderTaxDocument
                }}</span>
              </div>
              <em
                class="icon-check icon"
                v-if="destinationId === destinationPerson.id"
              ></em>
            </div>
          </li>
        </ul>
      </div>

      <div
        class="form-group col-12 col-w-full mb-4"
        v-if="destinationsList.length === 0"
      >
        <h4>Você ainda não tem favorecidos cadastrados.</h4>
      </div>
      <WhiteLabel>
        <button
          class="btn btn-block btn-outline-orange mb-3"
          @click="goToStep(0)"
        >
          Cadastrar favorecido
        </button>
      </WhiteLabel>
    </section>

    <section v-if="viewControl === 3.1">
      <h4 class="form-group col-12 mb-4">
        Informe os dados bancários do favorecido
      </h4>
      <div class="form-group col-12 col-w-full mb-4">
        <label for="inputAccountBankCode">Instituição bancária *</label>

        <select class="form-control mb-4" v-model="destination.bankNumber">
          <option value="-1">Selecione</option>
          <option
            v-for="bank in banksList"
            :key="bank.number"
            :value="bank.number"
          >
            {{ bank.number }} - {{ bank.name }}
          </option>
        </select>
        <small class="text-danger" v-if="errorMessages.bankNumber"
          >Selecione uma instituição bancária</small
        >
      </div>
      <div class="form-group col-12 col-w-full mb-4">
        <label for="inputAccountBankType">Tipo de conta *</label>
        <select class="form-control mb-4" v-model="destination.bankAccountType">
          <option value="corrente">Corrente</option>
          <option value="poupanca">Poupança</option>
        </select>
      </div>
      <div class="form-group col-12 col-w-full mb-4">
        <label for="inputAccountBankBranch">Agência (sem dígito) *</label>
        <input
          type="text"
          placeholder="1234"
          autocomplete="false"
          class="form-control mb-4"
          v-model="destination.bankBranchNumber"
          v-mask="[
            '####',
            '#####',
            '######',
            '#######',
            '########',
            '#########',
            '##########',
          ]"
        />
        <small
          class="text-danger"
          v-if="!$v.destination.bankBranchNumber.minLength"
          >Agência deve ter pelo menos 4 números</small
        >
      </div>
      <div class="form-group col-12 col-w-full mb-4">
        <label for="inputAccountBankNumber"
          >Número da conta (com dígito)*</label
        >
        <input
          type="text"
          placeholder="12345-6"
          autocomplete="false"
          class="form-control mb-4"
          v-model="destination.bankAccountNumber"
          v-mask="[
            '#-#',
            '##-#',
            '###-#',
            '####-#',
            '#####-#',
            '######-#',
            '#######-#',
            '########-#',
            '#########-#',
            '###########-#',
          ]"
        />
        <small class="text-danger" v-if="errorMessages.bankAccountNumber"
          >Campo obrigatório</small
        >
        <small class="text-danger" v-if="errorMessages.bankAccountMask"
          >Formato inválido</small
        >
      </div>

      <div
        class="form-group col-12 col-w-full mb-4"
        v-if="destination.bankAccountType === 'poupanca'"
      >
        <label for="inputAccountVariation">Número da variação da conta *</label>
        <input
          type="text"
          placeholder="12345-6"
          autocomplete="false"
          class="form-control mb-4"
          v-model="destination.bankAccountVariation"
        />
      </div>

      <WhiteLabel>
        <button
          @click="handleRegisterDestinationAccount"
          class="btn btn-block btn-orange mb-3"
        >
          Continuar
        </button>
      </WhiteLabel>
    </section>

    <section v-if="viewControl === 3.2">
      <h4 class="form-group col-12 mb-4">Qual valor você deseja transferir?</h4>
      <div class="form-group col-12 col-w-full mb-4">
        <money
          v-model="amount"
          class="input-value input-money"
          :decimal="','"
          :thousands="'.'"
          :precision="2"
        ></money>
        <small class="text-danger" v-if="errorMessages.minimumValueToTransfer"
          >O valor mínimo é de R${{ getMinimumValue }}</small
        >

        <small class="text-danger" v-if="errorMessages.balance"
          >Saldo insuficiente.</small
        >
      </div>
      <div class="d-flex form-group">
        <div class="form-group col-8 mb-4">Disponivel para transferência</div>
        <div class="form-group col-4 mb-4">
          <strong>R${{ getBalanceValue }}</strong>
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group col-8 mb-4">Taxa de transferência</div>
        <div class="form-group col-4 mb-4">
          <strong>R${{ getTedFeeValue }}</strong>
        </div>
      </div>
      <WhiteLabel class="mt-lg">
        <button @click="handleReview" class="btn btn-block btn-orange mb-3">
          Continuar
        </button>
      </WhiteLabel>
    </section>

    <section v-if="viewControl === 3.3">
      <div class="d-flex form-group">
        <div class="form-group col-12">
          <WhiteLabel>
            <label class="radio-selector mb-2 w-100 d-flex">
              <input
                type="radio"
                :checked="saveNewDestination"
                @click="handleChangeSaveDestination"
              />
              <div class="checkbox-badge mr-3"></div>
              <div class="d-flow">
                <h4>Salvar como favorecido</h4>
              </div>
            </label>
          </WhiteLabel>
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group col-2 mb-4">
          <i class="icon icon-small transfer gray"></i>
        </div>
        <div class="form-group col-10 mb-4">
          <label class="small-label">Valor</label>
          <label class="price-label">R${{ getAmountValue }}</label>
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group col-2 mb-4"></div>
        <div class="form-group col-10 mb-4">
          <label class="small-label">Taxa de transferência</label>
          <label class="small-price-label">R${{ getTedFeeValue }}</label>
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group col-2 mb-4"></div>
        <div class="form-group col-10 mb-4">
          <label class="small-label">Valor debitado na sua conta</label>
          <label class="small-price-label">
            <strong>R${{ getAmountAndFeeValue }}</strong>
          </label>
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group col-2">
          <i class="icon-small icon-wallet gray" />
        </div>
        <div class="form-group col-10">
          <label class="small-label">Destino</label>
          <label class="small-price-label">
            <strong>{{ getBankName(destination.bankNumber) }}</strong>
          </label>
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group col-2"></div>
        <div class="form-group col-10">
          <label class="small-price-label--inline">
            <strong>Agência:</strong>
          </label>
          {{ destination.bankBranchNumber }}
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group col-2"></div>
        <div class="form-group col-10">
          <label class="small-price-label--inline">
            <strong>Conta:</strong>
          </label>
          {{ destination.bankAccountNumber }}
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group col-2"></div>
        <div class="form-group col-10">
          <label class="small-price-label--inline">{{
            destination.holderName
          }}</label>
        </div>
      </div>
      <div class="d-flex form-group mb-4">
        <div class="form-group col-2"></div>
        <div class="form-group col-10">
          <label class="small-price-label--inline">{{
            destination.holderTaxDocument
          }}</label>
        </div>
      </div>
      <WhiteLabel class="mt-lg">
        <button @click="goToStep(3.4)" class="btn btn-block btn-orange mb-3">
          Continuar
        </button>
      </WhiteLabel>
    </section>

    <section v-if="viewControl === 3.4">
      <div class="form-group mb-4">
        <div class="d-flow">
          <h4>
            Selecione um método para recebimento. Este código expirará em 10
            minutos
          </h4>
        </div>
        <div class="row">
          <div class="col-3">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                :value="true"
                id="mobile-method"
                v-model="authCodeType"
              />
              <label class="form-check-label" for="mobile-method">SMS</label>
            </div>
          </div>
          <div class="col-9">
            <span>{{ getMobile }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                :value="false"
                id="email-method"
                v-model="authCodeType"
              />
              <label class="form-check-label" for="email-method">Email</label>
            </div>
          </div>
          <div class="col-9">
            <span>{{ getEmail }}</span>
          </div>
        </div>
      </div>
      <WhiteLabel>
        <button @click="goToStep(3.5)" class="btn btn-block btn-orange mb-3">
          Gerar código de autenticação
        </button>
      </WhiteLabel>
    </section>

    <section v-if="viewControl === 3.5">
      <input
        type="text"
        class="form-control mb-4"
        placeholder="Digite seu código de autorização"
        maxlength="6"
        v-mask="'######'"
        v-model="authCode"
      />
      <WhiteLabel>
        <button
          @click="handleTransfer"
          class="btn btn-block btn-orange mb-3"
          :disabled="authCode === ''"
        >
          Efetuar transferência
        </button>
      </WhiteLabel>
    </section>

    <section v-if="viewControl === 3.6">
      <div class="d-flex form-group">
        <div class="form-group col-2 mb-4">
          <i class="icon icon-small transfer gray"></i>
        </div>
        <div class="form-group col-10 mb-4">
          <label class="small-label">Valor</label>
          <label class="price-label">R${{ getAmountValue }}</label>
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group col-2 mb-4"></div>
        <div class="form-group col-10 mb-4">
          <label class="small-label">Taxa de transferência</label>
          <label class="small-price-label">R${{ getTedFeeValue }}</label>
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group col-2 mb-4"></div>
        <div class="form-group col-10 mb-4">
          <label class="small-label">Valor debitado na sua conta</label>
          <label class="small-price-label">
            <strong>R${{ getAmountAndFeeValue }}</strong>
          </label>
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group col-2">
          <i class="icon-small icon-wallet gray" />
        </div>
        <div class="form-group col-10">
          <label class="small-label">Destino</label>
          <label class="small-price-label">
            <strong>{{ getBankName(destination.bankNumber) }}</strong>
          </label>
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group col-2"></div>
        <div class="form-group col-10">
          <label class="small-price-label--inline">
            <strong>Agência:</strong>
          </label>
          {{ destination.bankBranchNumber }}
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group col-2"></div>
        <div class="form-group col-10">
          <label class="small-price-label--inline">
            <strong>Conta:</strong>
          </label>
          {{ destination.bankAccountNumber }}
        </div>
      </div>
      <div class="d-flex form-group">
        <div class="form-group col-2"></div>
        <div class="form-group col-10">
          <label class="small-price-label--inline">{{
            destination.holderName
          }}</label>
        </div>
      </div>
      <div class="d-flex form-group mb-4">
        <div class="form-group col-2"></div>
        <div class="form-group col-10">
          <label class="small-price-label--inline">{{
            destination.holderTaxDocument
          }}</label>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { Money } from 'v-money';
import WhiteLabel from '@/components/shared/WhiteLabel';
import Spinner from '@/components/theme/Spinner';
import { validateCpfCnpj } from '@/validations/cpfCnpj';
import { required, minLength } from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import AccountApi from '@/services/AccountApi';
import TwoFactorAuthError from '@/errors/TwoFactorAuthError';
import CashoutError from '@/errors/CashoutError';
import { bankAccountNumber } from '@/validations/bankAccountNumber';
import { Presets } from '@/models/Presets';

export default {
  name: 'ModalTED',

  components: {
    WhiteLabel,
    Money,
  },

  props: {
    viewControl: {
      type: Number,
      default: 0,
    },
    changeControl: {
      type: Function,
      default: () => 1,
    },
  },

  data: () => ({
    showRegisteredDestination: false,
    destinationsList: [],
    destinationId: 0,
    amount: 0,
    note: '',
    saveNewDestination: true,
    banksList: [],
    minimumValueToTransfer: 30,
    balance: 0,
    tedFee: 0,
    password: '',
    document: '',
    success: false,
    authCodeType: true,
    authCode: '',
    preset: new Presets(),
    destination: {
      holderName: '',
      holderTaxDocument: '',
      bankNumber: -1,
      bankAccountNumber: '',
      bankBranchNumber: '',
      bankAccountVariation: null,
      bankAccountType: 'corrente',
    },
    errorMessages: {
      holderName: false,
      holderTaxDocument: false,
      bankNumber: false,
      bankAccountNumber: false,
      bankBranchNumber: false,
      bankAccountMask: false,
      bankAccountVariation: null,
      bankAccountType: false,
      amount: false,
      balance: false,
      minimumValueToTransfer: false,
      password: false,
    },
  }),
  validations: {
    destination: {
      bankBranchNumber: {
        minLength: minLength(4),
      },
    },
  },
  computed: {
    getBalanceValue() {
      return (this.balance - this.tedFee).toFixed(2).replace('.', ',');
    },
    getAmountAndFeeValue() {
      const value = this.amount + this.tedFee;

      return value.toFixed(2).replace('.', ',');
    },
    getAmountValue() {
      return this.amount.toFixed(2).replace('.', ',');
    },
    getTedFeeValue() {
      return this.tedFee.toFixed(2).replace('.', ',');
    },
    getMinimumValue() {
      return this.minimumValueToTransfer.toFixed(2).replace('.', ',');
    },
    getEmail() {
      var index = this.preset.holder.email.indexOf('@');
      var length = this.preset.holder.email.length;

      return (
        this.preset.holder.email.substring(0, 2) +
        '*'.repeat(index - 2) +
        this.preset.holder.email.substring(index, length)
      );
    },
    getMobile() {
      return '*'.repeat(11) + this.preset.holder.mobile.substring(10, 15);
    },
  },

  mounted() {
    this.getBanks();
  },

  methods: {
    handleRegisterDestination() {
      this.errorMessages.holderName =
        this.destination.holderName.length === 0 ||
        this.destination.holderName
          .split(' ')
          .filter((a) => a.trim().length > 1).length < 2;

      this.errorMessages.holderTaxDocument =
        validateCpfCnpj(this.destination.holderTaxDocument) === false ||
        this.destination.holderTaxDocument == '';

      if (
        this.errorMessages.holderName ||
        this.errorMessages.holderTaxDocument
      ) {
        return;
      }

      this.goToStep(3.1);
    },

    getSizeDocument(inputDocument) {
      const newDocument = inputDocument.replace(/\.|-/g, '');
      return newDocument.length;
    },

    async handleRegisterDestinationAccount() {
      this.errorMessages.bankNumber = this.destination.bankNumber === -1;

      this.errorMessages.bankBranchNumber =
        this.destination.bankBranchNumber.length < 4;

      this.errorMessages.bankAccountNumber =
        this.destination.bankAccountNumber.length === 0;

      this.errorMessages.bankAccountMask =
        bankAccountNumber(this.destination.bankAccountNumber) === false;

      if (
        this.errorMessages.bankAccountNumber ||
        this.errorMessages.bankBranchNumber ||
        this.errorMessages.bankAccountNumber ||
        this.errorMessages.bankAccountMask
      )
        return;

      this.goToStep(3.2);

      await this.getPreset();
      await this.getBalance();
      await this.getMinimumValueToTransfer();
    },

    handleRegisteredDestination() {
      const api = new PaymentAccountsApi();
      api.getDestinations().then((result) => {
        this.showRegisteredDestination = !this.showRegisteredDestination;
        this.destinationsList = result.data.destinations;
      });
    },

    handleReview() {
      this.errorMessages.minimumValueToTransfer =
        this.amount < this.minimumValueToTransfer;
      this.errorMessages.balance = this.amount > this.balance - this.tedFee;

      if (
        this.errorMessages.minimumValueToTransfer ||
        this.errorMessages.balance
      )
        return;

      this.goToStep(3.3);
    },

    handleChangeSaveDestination() {
      this.saveNewDestination = !this.saveNewDestination;
    },

    async selectDestination(destination) {
      this.destination = {
        holderName: destination.bankAccountHolderName,
        holderTaxDocument: destination.bankAccountHolderTaxDocument,
        bankNumber: destination.bankNumber,
        bankAccountNumber: destination.bankAccountNumber,
        bankBranchNumber: destination.bankBranchNumber,
        bankAccountVariation: destination.bankAccountVariation,
        bankAccountType: destination.bankAccountType,
      };

      await this.getPreset();
      await this.getBalance();
      await this.getMinimumValueToTransfer();
      this.showRegisteredDestination = false;
      this.goToStep(3.2);
    },

    async goToStep(step) {
      if (step === 3.5) {
        var result = await this.getAuthCode();

        if (result.data.error) {
          this.$alert.error(
            'Ops',
            new TwoFactorAuthError(result.data).getMessage()
          );
          this.$bus.$emit('spinner-stop');
          return;
        }
      }

      this.changeControl(step);
    },

    async getBanks() {
      const api = new AccountApi();
      const result = await api.banks();

      this.banksList = result.banks;
    },

    getBankName(bankNumber) {
      if (!bankNumber) return;

      for (let i = 0; i <= this.banksList.length; i++) {
        if (this.banksList[i].number === bankNumber) {
          return this.banksList[i].name;
        }
      }

      return 'Desconhecido';
    },

    async getBalance() {
      const api = new PaymentAccountsApi();
      const result = await api.getBalance('ted');

      if (result.currentBalance) {
        this.tedFee = result.tedFee || 0;
        this.balance = result.currentBalance || 0;
      }
    },

    async getMinimumValueToTransfer() {
      const api = new PaymentAccountsApi();
      const result = await api.getMinimumValueToTransfer();

      this.minimumValueToTransfer =
        result.amount || this.minimumValueToTransfer;
    },

    async handleTransfer() {
      this.$bus.$emit('spinner-run');
      const api = new PaymentAccountsApi();
      const accountApi = new AccountApi();

      api
        .createCashoutRequest(this.amount, this.destination, this.authCode)
        .then((cashoutResponse) => {
          if (cashoutResponse.status == 400) {
            this.$alert.error(
              'Ops',
              'Algum dado informado foi considerado inválido. Favor verifique as informações repassadas e tente novamente \n' +
                cashoutResponse.data.errors
            );
            this.$bus.$emit('spinner-stop');

            return;
          }

          if (cashoutResponse.data.error) {
            const cashoutError = new CashoutError(cashoutResponse.data);
            this.$alert.error('Ops', cashoutError.getMessage());
            this.$bus.$emit('spinner-stop');

            return;
          }

          if (
            cashoutResponse.status === 200 &&
            cashoutResponse.data.status === 'reproved'
          ) {
            let message = 'Ocorreu algum problema durante a solicitação.';

            if (cashoutResponse.data.descriptionError)
              message = cashoutResponse.data.descriptionError;

            this.$alert.error('Ops', message);
            this.$bus.$emit('spinner-stop');

            return;
          }

          if (cashoutResponse.data.id) {
            this.success = true;
            this.$bus.$emit('spinner-stop');

            if (this.saveNewDestination) {
              api.createDestination(this.destination);
            }

            this.goToStep(3.6);
          }
        });
    },

    async getAuthCode() {
      const api = new PaymentAccountsApi();

      return await api.getAuthCode(
        {
          splitterId: this.splitterId,
          mobile: this.authCodeType,
        },
        'ted'
      );
    },

    async getPreset() {
      const api = new AccountApi();
      const result = await api.presets();
      this.preset = result;
    },
  },
};
</script>
<style lang="scss" scoped>
.ted-container {
  padding: 1rem;
}
.input-money {
  font-size: 48px;
  max-width: 100%;
  border: none;
  outline: none;
  font-weight: lighter;
  border-bottom: solid thin;
  border-color: #a1a4b1;
}
.mt-lg {
  margin-top: 80px;
}
.small-label {
  display: block;
  text-transform: uppercase;
  color: #7d8287;
  font-size: 12px;
}
.price-label {
  display: block;
  font-size: 30px;
  color: #7d8287;
}
.small-price-label {
  display: block;
  color: #7d8287;

  &--inline {
    display: inline;
  }
}
.icon-small {
  width: 20px;
  height: 20px;
}
label .checkbox-badge {
  margin-top: 5px;
}
.destinations-list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 10px;
    margin: 0 0 20px 0;
    background: #f2f2f2;
    border: solid thin #dbdbdb;
    cursor: pointer;
  }
}
</style>
