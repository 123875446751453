import CustomApiError from './CustomError';

export default class CashoutError extends CustomApiError {
  toDictionary() {
    return {
      DOES_NOT_HAVE_MINIMUM_AMOUNT:
        'O titular não possui o valor mínimo para transferência.',
      AMOUNT_NOT_AVAILABLE: 'Não possui saldo disponível',
      BALANCE_BLOCKED: 'O saldo está bloqueado',
      PAYMENT_ACCOUNT_NOT_FOUND: 'Conta de pagamento não encontrada',
      TED_FEE_NOT_FOUND: 'Taxa de TED não encontrada',
      FAILED_REQUEST_TED: 'Falha na solicitação da transferência (TED)',
      DESTINATION_NOT_FOUND: 'Conta bancária destino não encontrada',
      OPERATING_HOURS_INVALID:
        'Horário indisponível para solicitar transferência',
      '2FA_TOKEN_INVALID': 'Token 2FA inválido (expirado)',
      SPLITTER_NOT_FOUND: 'Splitter não encontrado',
      AUTH_CODE_INVALID: 'Código de autorização inválido',
      SERVICE_UNAVAILABLE: 'Serviço de TED indisponível',
    };
  }
}
