<template>
  <div class="details-transference">
    <div class="info-transference ml-3">
      <em class="icon transfer gray-2"></em>
      <div class="data">
        <div>
          <h1 class="text-uppercase label">valor</h1>
          <h1 class="block value-1">
            {{
              value
                | currency('R$ ', 2, {
                  decimalSeparator: ',',
                  thousandsSeparator: '.',
                })
            }}
          </h1>
        </div>
        <div>
          <h1 class="text-uppercase label">TAXA DE TRANSFERÊNCIA</h1>
          <h1 class="block value-2">
            +{{
              fee
                | currency('R$ ', 2, {
                  decimalSeparator: ',',
                  thousandsSeparator: '.',
                })
            }}
          </h1>
        </div>
        <div>
          <h1 class="text-uppercase label">VALOR DEBITADO DA SUA CONTA</h1>
          <h1 class="block value-3">
            {{
              total
                | currency('R$ ', 2, {
                  decimalSeparator: ',',
                  thousandsSeparator: '.',
                })
            }}
          </h1>
        </div>
      </div>
    </div>
    <div
      class="info-transference ml-3"
      v-if="hasPrediction && estimated !== '' && estimated !== null"
    >
      <em class="icon calendar-outline gray-2"></em>
      <div class="data">
        <div>
          <h1 class="text-uppercase label">Previsão</h1>
          <h1 class="block text-uppercase value-3">{{ estimated }}</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppDetailsTransference',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    fee: {
      type: Number,
      default: 0,
    },
    hasPrediction: {
      type: Boolean,
      default: true,
    },
    estimated: {
      type: String,
      default: '',
    },
    days: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    total() {
      return this.value + this.fee;
    },
  },
};
</script>

<style lang="scss">
.details-transference {
  background-color: #f5f5f5;
  width: 100%;
  margin-bottom: 0;

  .info-transference {
    .data {
      h1 {
        margin-bottom: 0px;
      }
      div {
        padding-bottom: 1rem;
      }
      .label {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #adb5bd;
      }

      .value-1 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 35px;
        color: #7d8287;
      }

      .value-2 {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #7d8287;
      }

      .value-3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #7d8287;
      }
    }
  }

  .info-transference {
    display: flex;
    align-items: flex-start;

    .icon {
      font-size: 2rem;
    }
  }

  .total-received {
    .value-total {
      font-size: 2rem;
      margin-bottom: 1rem;
    }

    .value {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
  }
}
</style>
