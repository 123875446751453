<template>
  <div>
    <ModalBase
      title="Entenda seu saldo"
      :hasFooter="false"
      size="modal-md"
      :id="id"
    >
      <div slot="content" class="content-modal-balance-types">
        <div class="definition">
          <h1>Saldo em conta</h1>
          <p>
            É o seu saldo disponível para movimentação. Nele estão inclusas as
            transações que foram repassadas ou antecipadas. Você pode
            transferí-lo para sua conta bancária ou manter ele em sua Conta
            Pagcerto para decidir o que fazer depois. Caso você esteja vendo um
            montante seguido da legenda "Valor retido pela análise de risco"
            compreenda que esse é um valor bloqueado
            <i><b>temporariamente</b></i
            >, que está sendo analisado pela equipe responsável e, até que haja
            a conclusão dessa análise, ele é descontado do seu saldo em conta.
          </p>
        </div>
        <div class="definition">
          <h1>Saldo a receber</h1>
          <p>
            É a soma de todas as transações que ainda não foram repassadas ou
            disponibilizadas na sua Conta Pagcerto mas já tem uma data prevista
            de recebimento.
          </p>
        </div>
        <div class="definition">
          <h1>Saldo antecipável</h1>
          <p>
            É a parte do saldo a receber que você pode antecipar e receber antes
            da data prevista. Perfeito para usar naqueles momentos de
            emergência.
          </p>
        </div>
      </div>
    </ModalBase>
  </div>
</template>

<script>
import ModalBase from '@/components/shared/ModalBase';

export default {
  name: 'AppModalBalanceTypes',
  components: {
    ModalBase,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
.content-modal-balance-types {
  padding: 0.5rem 1.5rem 1.5rem 1.5rem;
}

.definition {
  padding-bottom: 28px;
  h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: #5e5e5e;
  }

  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    color: #5e5e5e;
  }
}
</style>
