<template>
  <div>
    <div class="favored">
      <form autocomplete="off">
        <div class="search form-group">
          <em class="icon-magnifier icon" />
          <input
            type="text"
            class="input-search"
            id="favored"
            placeholder="Buscar nome, CPF/CNPJ"
            v-model="searching"
            autocomplete="false"
          />
        </div>
      </form>
      <div class="list-favored">
        <div
          class="user-favored"
          v-for="favored of list"
          :key="favored.id"
          @click="selectUser(favored)"
        >
          <em
            class="icon icon-user"
            v-if="getSizeDocument(getFavoredTaxDocument(favored)) === 11"
          />
          <em v-else class="company-icon gray-2 icon" />
          <div class="data-favored d-flex flex-column">
            <span class="font-weight-bold user-name">
              {{
                isSellers
                  ? favored.company
                    ? favored.company.tradeName
                    : favored.fullName
                  : favored.name
              }}
            </span>
            <span class="user-document">
              {{ getFavoredTaxDocument(favored) }}
              -
              {{ isSellers ? 'Estabelecimento' : 'Splitter' }}
            </span>
          </div>
          <WhiteLabel>
            <em class="icon-check icon" v-if="userSelected === favored" />
          </WhiteLabel>
        </div>
      </div>
      <WhiteLabel>
        <a
          v-if="isSellers && loadMoreAvaliable"
          type="button"
          name="confirmar"
          class="
            btn btn-orange btn-gray btn-outline
            pl-5
            pr-5
            align-self-center
            mt-2
          "
          @click="loadMoreSellers()"
        >
          Carregar mais
        </a>
      </WhiteLabel>
      <WhiteLabel>
        <button
          v-if="userSelected !== null"
          type="button"
          name="confirmar"
          class="
            btn btn-orange btn-gray btn-pill
            pl-5
            pr-5
            align-self-center
            mt-4
            mb-4
          "
          @click="confirm()"
        >
          Confirmar
        </button>
      </WhiteLabel>
    </div>
  </div>
</template>
<script>
import ModalBase from '@/components/shared/ModalBase';
import AppModalHowMuchTransfer from '@/components/account/transference/ModalHowMuchTransfer';
import PaymentsApi from '@/services/PaymentsApi';
import AccountApi from '@/services/AccountApi';
import WhiteLabel from '@/components/shared/WhiteLabel';
import { UserFilter } from '@/models/User';
import _ from 'lodash';

export default {
  name: 'AppModalWhoIstheFavored',

  components: {
    WhiteLabel,
  },

  props: {
    id: {
      type: String,
      default: '',
    },
    isSellers: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    listFavoredfiltered: [],
    listSplitters: null,
    listSellers: null,
    userSelected: null,
    modalHowMuchTransfer: 'modalHowMuchTransferss',
    searching: '',
    searchingDebounce: '',
    sellerFilter: new UserFilter(),
  }),

  computed: {
    list() {
      return this.listFavoredfiltered;
    },
    loadMoreAvaliable() {
      if (this.listSellers == null) return false;
      return this.listSellers.count > this.listSellers.sellers.length;
    },
  },

  watch: {
    listSplitters: function (val) {
      this.listFavoredfiltered = val.splitters;
    },
    listSellers: function (val) {
      this.listFavoredfiltered = val.sellers;
    },
    searching: _.debounce(function (newValue) {
      this.searchingDebounce = newValue;
    }, 300),
    searchingDebounce: function (newValue) {
      this.filter();
    },
  },

  async mounted() {
    await this.getListUsersAccount();

    this.$bus.$on('cleanInput', (result) => {
      this.cleanInput();
    });
  },

  methods: {
    async getListUsersAccount(loadMore = false) {
      this.$bus.$emit('spinner-run');

      if (this.isSellers) {
        const api = new AccountApi();
        let response = await api.getSellersFromApp(this.sellerFilter);
        if (response.status !== 400) {
          if (loadMore) {
            this.listSellers.sellers = this.listSellers.sellers.concat(
              response.data.sellers
            );

            var aux = { ...this.listSellers };
            this.listSellers = {};
            this.listSellers = aux;
          } else {
            this.listSellers = response.data;
          }
        } else {
          this.listSellers = [];
        }
      } else {
        const api = new PaymentsApi();
        let response = await api.getListUsers(this.searchingDebounce);
        if (response.status !== 400) {
          this.listSplitters = response.data;
        } else {
          this.listSplitters = [];
        }
      }

      this.$bus.$emit('spinner-stop');
    },

    confirm() {
      var userToEmit;
      if (this.isSellers) {
        userToEmit = { ...this.userSelected };
        userToEmit.name = userToEmit.fullName;
        userToEmit.bankAccount = {
          taxDocument: this.getFavoredTaxDocument(this.userSelected),
        };
      } else {
        userToEmit = this.userSelected;
      }
      this.$emit('updateSplitter', userToEmit, 2.1);
    },

    selectUser(favored) {
      this.userSelected = favored;
    },

    async filter() {
      const searching = this.searchingDebounce.toUpperCase();
      if (this.isSellers) {
        this.sellerFilter.fullName = '';
        this.sellerFilter.taxDocument = '';
        this.allLetters(searching)
          ? (this.sellerFilter.fullName = searching)
          : (this.sellerFilter.taxDocument = searching);
        await this.getListUsersAccount();
      } else {
        if (searching) {
          this.listFavoredfiltered = this.listSplitters.splitters.filter(
            (user) => {
              return (
                user.name.toUpperCase().includes(searching) ||
                user.bankAccount.taxDocument.includes(searching)
              );
            }
          );
        } else {
          this.listFavoredfiltered = this.listSplitters.splitters;
        }
      }
    },

    allLetters(inputtxt) {
      var letters = /^[A-Za-z]+$/;
      if (inputtxt.match(letters)) {
        return true;
      } else {
        return false;
      }
    },

    getSizeDocument(document) {
      document = document.replace(/\.|-/g, '');
      return document.length;
    },

    cleanInput() {
      this.searching = '';
      this.searchingDebounce = '';
      this.userSelected = null;
    },

    async loadMoreSellers() {
      this.sellerFilter.offset.index += this.sellerFilter.offset.limit;
      await this.getListUsersAccount(true);
    },

    getFavoredTaxDocument(favored) {
      if (this.isSellers) {
        if (favored.company == null) return favored.taxDocument;
        else return favored.company.taxDocument;
      } else {
        return favored.bankAccount.taxDocument;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.favored {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon.company-icon {
    height: 1.5rem;
  }

  form {
    width: 100%;
  }

  .search {
    display: inline-flex;
    width: 100%;
    .icon {
      padding: 0.8rem;
      font-size: 2rem;
    }

    .input-search {
      border: 0;
      border-bottom: 1px solid #f2f2f2;
      width: 100%;
    }
  }

  .list-favored {
    align-self: flex-start;
    margin-top: 2.5rem;
    width: 100%;

    .user-favored {
      display: flex;
      margin-bottom: 1rem;

      .icon {
        font-size: 1rem;
        padding-bottom: 0.5rem;
        padding-right: 0.8rem;
      }

      .icon.icon-check {
        color: #ff7606;
      }

      .data-favored {
        width: 100%;
      }

      .user-name,
      .user-document {
        font-family: Roboto;
        font-style: normal;
      }
      .user-name {
        font-size: 1rem;
        color: #8e8e8e;
        padding-bottom: 0.2rem;
      }

      .user-document {
        font-weight: normal;
        font-size: 0.9rem;
        color: #d1d1d1;
      }
    }
  }
}
</style>
