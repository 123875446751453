import CustomApiError from './CustomError';

export default class TwoFactorAuthError extends CustomApiError {
  toDictionary() {
    return {
      REPROVED_ACCOUNT: 'A conta está reprovada.',
      BLACKLISTED_ACCOUNT: 'O titular está na blacklist.',
      TAX_DOCUMENT_INVALID: 'Documento inválido.',
      WRONG_PASSWORD: 'Senha incorreta.',
      OPERATING_HOURS_INVALID:
        'O horário de funcionamento do ted é 07:45 às 15:45.',
      FAILED_TO_GENERATE_AUTH_CODE: 'Falha ao gerar o código, tente novamente.',
      NOT_FOUND_AUTH_TYPE: 'O formato para geração do codigo não existe.',
    };
  }
}
