<template>
  <div class="list-options pt-2">
    <div class="row" v-if="addTransferPermission && showTransferLink">
      <div class="col-12 col-w-full">
        <a
          data-toggle="modal"
          :data-target="'#' + modalTransferBase"
          class="sidebar-nav-link pointer"
          v-if="!balance.balanceBlocked"
        >
          <div class="gray-card ml-2 mb-2">
            <!-- <i class="icon transfer"></i> -->
            <i class="icon-shuffle"></i>
            <h1 class="title">Transferir</h1>
          </div>
        </a>
        <a
          data-toggle="modal"
          :data-target="'#' + modalBalanceBlocked"
          class="sidebar-nav-link"
          v-if="balance.balanceBlocked"
        >
          <div class="gray-card ml-2 mb-2">
            <!-- <i class="icon transfer"></i> -->
            <i class="icon-shuffle"></i>
            <h1 class="title">Transferir</h1>
          </div>
        </a>
        <AppModalBalanceBlocked :id="modalBalanceBlocked" />
        <AppModalTransferBase :id="modalTransferBase" :balance="balance" />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-w-full">
        <router-link
          v-if="showPixLink"
          :to="{ name: 'pix-order-payments' }"
          class="sidebar-nav-link"
        >
          <div class="gray-card ml-2 mb-2">
            <PixLogo />
            <h1 class="title">Pix</h1>
          </div>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-w-full">
        <router-link
          v-if="showBalanceExtractLink"
          :to="{ name: 'balance-extract' }"
          class="sidebar-nav-link"
        >
          <div class="gray-card ml-2 mb-2">
            <i class="icon-calculator"></i>
            <h1 class="title">Ver extrato</h1>
          </div>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-w-full">
        <router-link
          v-if="showBillPaymentLink"
          :to="{ name: 'bills-payment' }"
          class="sidebar-nav-link cursor-pointer"
        >
          <div class="gray-card ml-2 mb-2">
            <i class="icon-briefcase"></i>
            <h1 class="title mb-2">Pagamento de</h1>
            <h1 class="title">contas</h1>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import AppModalBalanceBlocked from '@/components/account/transference/modal/ModalBalanceBlocked';
// import AppModalPreferencesBase from '@/components/account/preferences/ModalPreferencesBase';
import AppModalTransferBase from '@/components/account/transference/modal/ModalTransferBase';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import { PermissionList } from '@/models/Permission';
import { mapGetters } from 'vuex';
import PixLogo from '@/components/shared/PixLogo';

export default {
  name: 'ListOptions',

  components: {
    AppModalTransferBase,
    AppModalBalanceBlocked,
    PixLogo,
  },

  props: {
    balance: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    modalBalanceBlocked: 'modalBalanceBlocked',
    modalTransferBase: 'modalTransferBase',
    settings: null,
    automaticDays: null,
    // modalPreferencesBase: 'modalPreferencesBase',
    permissionList: new PermissionList(),
  }),

  async mounted() {
    await this.getPreferencesAccount();

    this.$bus.$on('closePreferences', (result) => {
      this.getPreferencesAccount();
    });
  },

  computed: {
    getSettingsFormatting() {
      let daysFormatting = '';
      if (this.settings) {
        if (this.settings.isMonday) daysFormatting = 'Seg, ';
        if (this.settings.isTuesday) daysFormatting += 'Ter, ';
        if (this.settings.isWednesday) daysFormatting += 'Qua, ';
        if (this.settings.isThursday) daysFormatting += 'Qui, ';
        if (this.settings.isFriday) daysFormatting += 'Sex,';

        if (!daysFormatting) {
          daysFormatting = 'Desligado';
        } else {
          const last = daysFormatting.lastIndexOf(',');
          daysFormatting = daysFormatting.substring(last, '');
        }
      }

      return daysFormatting;
    },

    showAnticipated() {
      var hasAnticipatedBalance = this.balance.anticipatedBalance <= 0;
      var anticipableTransferPlan =
        this.whoAmI.account.transferPlan.daysPinpad === 2;

      if (
        (this.whoAmI.account.transferPlan.anticipated ||
          anticipableTransferPlan) &&
        this.anticipationPermission &&
        hasAnticipatedBalance
      ) {
        return false;
      } else {
        return true;
      }
    },

    anticipationPermission() {
      var permission = this.permissions.filter((item) => {
        if (item.name === 'payments.anticipation') return item;
      });

      if (
        (permission.grant === 'edit' ||
          permission.grant === 'readonly' ||
          this.isAccountHolder) &&
        (!this.isOriginPartner ||
          (this.isAccountHolder && !this.isAccountPartner))
      ) {
        return true;
      } else {
        return false;
      }
    },

    anticipationPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'payments.anticipation'
      );
    },

    paymentAccountTransferPermissionIndex() {
      return this.permissions.findIndex((x) => x.name === 'paymentAccounts');
    },

    paymentAccountTransferManagementPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'paymentAccounts.settings'
      );
    },

    paymentAccountTransferManagementPermission() {
      if (
        (this.permissionList.permissions[
          this.paymentAccountTransferManagementPermissionIndex
        ] != null &&
          (this.permissionList.permissions[
            this.paymentAccountTransferManagementPermissionIndex
          ].grant === 'readonly' ||
            this.permissionList.permissions[
              this.paymentAccountTransferManagementPermissionIndex
            ].grant === 'edit')) ||
        this.isAccountHolder
      ) {
        return true;
      } else {
        return false;
      }
    },

    addTransferPermission() {
      if (
        (this.permissions[this.paymentAccountTransferPermissionIndex] != null &&
          this.permissions[this.paymentAccountTransferPermissionIndex].grant ===
            'create') ||
        this.isAccountHolder
      ) {
        return true;
      } else {
        return false;
      }
    },

    ...mapGetters({
      whoAmI: 'account/whoAmI',
      permissions: 'account/permissions',
      routeBlocked: 'blockedFunctionalities/anyIsBlocked',
    }),

    isOriginPartner() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.isOriginPartner;
    },

    isAccountHolder() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByHolder;
    },

    isAccountPartner() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByPartner;
    },

    showPixLink() {
      return !this.routeBlocked(['pix', 'pix-minhas-movimentacoes']);
    },

    showTransferLink() {
      return !this.routeBlocked(['conta', 'conta-transferencias']);
    },

    showBalanceExtractLink() {
      return !this.routeBlocked(['conta', 'conta-saldo-extrato']);
    },

    showBillPaymentLink() {
      return !this.routeBlocked(['conta', 'conta-pagamento-contas']);
    },
  },

  methods: {
    async getPreferencesAccount() {
      this.$bus.$emit('spinner-run');
      const api = new PaymentAccountsApi();
      this.settings = await api.getSettings();
      this.$bus.$emit('spinner-stop');
    },
    // openModal() {
    //   /* eslint-disable no-undef */
    //   $('#modalPreferencesBase').modal('show');
    // },
  },
};
</script>

<style>
.cursor-pointer {
  cursor: pointer;
}

.font-size i {
  height: 18px;
  width: 18px;
}
</style>
