<template>
  <div>
    <ModalBase :hasFooter="false" :id="id" size="modal-md">
      <div slot="content" class="content-modal-transfer">
        <div class="d-flex flex-column align-items-center">
          <div class="icon-modal">
            <em class="icon-lock"></em>
          </div>
          <div class="content-text">
            <h1>Saldo bloqueado</h1>
            <p>
              No momento não é possível transferir seu saldo. Você poderá
              solicitar uma transferência assim que ele for liberado.
            </p>
            <div class="link">
              <p>
                Para tirar dúvidas sobre o bloqueio,
                <a>entre em contato</a> com nosso suporte.
              </p>
            </div>
          </div>
          <button class="btn btn-ok" data-dismiss="modal">Ok, entendi!</button>
        </div>
      </div>
    </ModalBase>
  </div>
</template>

<script>
import ModalBase from '@/components/shared/ModalBase';

export default {
  name: 'AppModalBalanceBlocked',
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  components: {
    ModalBase,
  },
};
</script>

<style lang="scss" scoped>
.content-modal-transfer {
  padding: 2rem;
  .icon-modal {
    padding-top: 3rem;
    padding-bottom: 1rem;
    .icon-lock {
      font-size: 6rem;
      color: #8914c6;
    }
  }
  .content-text {
    font-family: Roboto;
    font-style: normal;
    color: #7d8287;
    text-align: center;
    padding-bottom: 6rem;
    h1 {
      font-weight: 300;
      font-size: 24px;
      line-height: 28px;
    }
    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
    }
    a {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      color: #24bffc;
    }

    .link {
      padding-top: 3rem;
    }
  }

  .btn-ok {
    width: 312px;
    height: 48px;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #ffffff;

    background: #4d95ea;
    border-radius: 6px;
  }
}
</style>
