<template>
  <div>
    <div slot="content" class="content-modal-transfer-data">
      <div>
        <h1 class="title-confirm-transfer-data">
          Confirme os dados da transferência
        </h1>
      </div>
      <div v-if="typeTransfer === 1">
        <AppTrasferOwnAccount :value="value" :fee="fee" />
      </div>
      <div v-if="typeTransfer === 0">
        <AppTransferOtherAccount :value="value" :splitter="splitter" />
      </div>
      <div class="div-btn">
        <WhiteLabel>
          <button class="btn btn-orange" @click="confirm">Confirmar</button>
        </WhiteLabel>
      </div>
    </div>
  </div>
</template>

<script>
import ModalBase from '@/components/shared/ModalBase';
import AppTransferOtherAccount from '@/components/account/transference/TransferOtherAccount';
import AppTrasferOwnAccount from '@/components/account/transference/TransferOwnAccount';
import AppModalRequestTransfer from '@/components/account/transference/ModalRequestTransfer';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppModalConfirmTransferData',
  components: {
    AppTrasferOwnAccount,
    AppTransferOtherAccount,
    WhiteLabel,
  },
  data() {
    return {
      modalRequestTransfer:
        'modalRequestTransfer' + Math.random().toString('16').slice(2, 8),
    };
  },
  props: {
    // id: String,
    typeTransfer: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    fee: {
      type: Number,
      required: true,
    },
    splitter: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    confirm() {
      if (this.typeTransfer === 1) this.$emit('getConfirm', 1.2);
      else this.$emit('getConfirm', 2.3);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-modal-transfer-data {
  padding: 2rem 2rem 2rem 2rem;

  .div-btn {
    display: flex;
    justify-content: center;
  }
  .btn-confirm {
    width: 200px;
    height: 48px;
    bottom: 24px;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.15);
    background: linear-gradient(147.43deg, #ff7606 20.2%, #fa3645 100%);
    border-radius: 40px;

    color: white;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.5px;
  }
  .title-confirm-transfer-data {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 37px;
    color: #7d8287;

    padding-bottom: 3rem;
  }
}
</style>
