<template>
  <div class="modal-transfer-base">
    <ModalBase
      :hasFooter="false"
      :title="title"
      :id="id"
      :btnBackgroudGray="btnBackgroudGray"
      :size="
        control === 1 || control === 2.1
          ? 'how-much-transfer'
          : control === 0
          ? 'preferences-account-size'
          : 'transference-size'
      "
    >
      <div slot="content" v-if="control === 0">
        <AppModalSelectDestination
          :balance="balance.currentBalance"
          @clicked="changeControl"
        />
      </div>
      <!-- Fluxo de transferencia para a proria conta -->
      <div slot="content" v-if="control === 1">
        <AppModalHowMuchTransfer
          :typeTransfer="typeTransfer.ownAccount"
          @getValue="setValue"
        />
      </div>
      <div slot="content" v-if="control === 1.1">
        <AppModalConfirmTransferData
          :typeTransfer="typeTransfer.ownAccount"
          :value="value"
          :fee="balance.transferFee"
          :splitter="favored"
          @getConfirm="changeControl"
        />
      </div>
      <div slot="content" v-if="control === 1.2">
        <AppModalRequestTransfer
          :value="value"
          :typeTransfer="typeTransfer.ownAccount"
          :fee="balance.transferFee"
          :splitter="favored"
          @getConfirm="changeControl"
        />
      </div>
      <div slot="content" v-if="control === 1.3">
        <AppModalSuccessTransference
          :value="value"
          :typeTransfer="typeTransfer.ownAccount"
          :fee="balance.transferFee"
          :destination="favored"
        />
      </div>

      <!-- Fluxo de transferencia para outro usuario -->
      <div slot="content" v-if="control === 1.7">
        <AppModalSellerOrSplitter
          @splitters-selected="
            changeControl(2);
            isSellers = false;
          "
          @sellers-selected="
            changeControl(2);
            isSellers = true;
          "
        />
      </div>
      <div slot="content" v-if="control === 2">
        <AppModalWhoIstheFavored
          :typeTransfer="typeTransfer.otherSplitterAccount"
          :isSellers="isSellers"
          @updateSplitter="updateFavored"
        />
      </div>
      <div slot="content" v-if="control === 2.1">
        <AppModalHowMuchTransfer
          :typeTransfer="typeTransfer.otherSplitterAccount"
          :splitter="favored"
          @getValue="setValue"
        />
      </div>
      <div slot="content" v-if="control === 2.2">
        <AppModalConfirmTransferData
          :typeTransfer="typeTransfer.otherSplitterAccount"
          :value="value"
          :fee="balance.transferFee"
          :splitter="favored"
          @getConfirm="changeControl"
        />
      </div>
      <div slot="content" v-if="control === 2.3">
        <AppModalRequestTransfer
          :value="value"
          :typeTransfer="
            isSellers
              ? typeTransfer.otherSellerAccount
              : typeTransfer.otherSplitterAccount
          "
          :fee="balance.transferFee"
          :recipient="favored"
          @transfer-other="setFee($event)"
        />
      </div>
      <div slot="content" v-if="control === 2.4">
        <AppModalSuccessTransference
          :value="value"
          :typeTransfer="typeTransfer.otherSplitterAccount"
          :fee="fee"
          :destination="favored"
        />
      </div>
      <div slot="content" v-if="control >= 3 && control < 4">
        <ModalTED :changeControl="changeControl" :viewControl="control" />
      </div>
    </ModalBase>
  </div>
</template>

<script>
import AppModalSelectDestination from '@/components/account/transference/ModalSelectDestination';
import AppModalHowMuchTransfer from '@/components/account/transference/ModalHowMuchTransfer';
import AppModalWhoIstheFavored from '@/components/account/transference/ModalWhoIstheFavored';
import AppModalConfirmTransferData from '@/components/account/transference/modal/ModalConfirmTransferData';
import AppModalRequestTransfer from '@/components/account/transference/ModalRequestTransfer';
import AppModalSuccessTransference from '@/components/account/transference/modal/ModalSuccessTransference';
import AppModalSellerOrSplitter from '@/components/account/transference/modal/ModalSellerOrSplitter';
import ModalTED from '@/components/account/transference/ModalTED';
import ModalBase from '@/components/shared/ModalBase';

export default {
  name: 'AppModalTransferBase',
  components: {
    ModalBase,
    ModalTED,
    AppModalSelectDestination,
    AppModalHowMuchTransfer,
    AppModalWhoIstheFavored,
    AppModalConfirmTransferData,
    AppModalRequestTransfer,
    AppModalSuccessTransference,
    AppModalSellerOrSplitter,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    balance: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: () => ({
    control: 0,
    title: 'Selecione o destino da transferência',
    controls: {
      0: 'Selecione o destino da transferência',
      1: 'Quanto você deseja transferir?',
      1.7: 'Deseja transferir para um splitter ou  estabelecimento?',
      2: 'Quem será o favorecido?',
      2.1: 'Quanto você deseja transferir?',
      2.4: 'Transferência efetuada',
      3: 'Quem será o favorecido?',
      3.1: 'Dados bancários',
      3.2: 'Valor',
      3.3: 'Confirmar dados',
      3.4: 'Código de autenticação',
      3.5: 'Informe o código recebido',
      3.6: 'Transferência efetuada',
    },
    typeTransfer: {
      otherSplitterAccount: 0,
      otherSellerAccount: 0.5,
      ownAccount: 1,
    },
    value: 0,
    favored: {},
    btnBackgroudGray: false,
    isSellers: false,
    fee: null,
  }),
  mounted() {
    this.$bus.$on('cleanInput', (result) => {
      this.changeControl(0);
      this.btnBackgroudGray = false;
    });
  },
  methods: {
    changeControl(number) {
      this.control = number;
      this.title = this.controls[number];

      if (this.control === 2.4 || this.control === 1.3) {
        this.btnBackgroudGray = true;
      }
    },
    setValue(val, favored, control) {
      this.value = val;
      this.favored = favored;
      this.changeControl(control);
    },
    setFee(data) {
      this.fee = data.fee;
      this.changeControl(data.control);
    },
    updateFavored(favored, control) {
      this.favored = favored;
      this.changeControl(control);
    },
  },
};
</script>

<style lang="scss">
.modal-transfer-base {
  .content-modal-transfer .transfer-other-account {
    background-color: white !important;
    padding-left: 0rem;
  }
}
</style>
