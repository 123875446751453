<template>
  <div>
    <div>
      <AuthCodeModalSection
        v-if="typeTransfer == 0.5"
        :subSection="authCodeSubSection"
        :email="preset.holder.email"
        :mobile="preset.holder.mobile"
        type="internal-transfer"
        lastButtonText="Transferir"
        @auth-sent="authCodeNextSection($event)"
        @code-ready="sendTransference($event)"
      >
        <input
          type="password"
          :id="idInput2"
          class="form-control"
          aria-describedby="password"
          v-model="document"
          minlength="4"
          required
        />
      </AuthCodeModalSection>
      <div v-else class="form-group col-12 col-w-full mb-4">
        <div class="form-group col-12 mb-4">
          <div class="d-flow">
            <h4>Digite sua senha para continuar</h4>
          </div>
          <input
            type="password"
            :id="idInput"
            class="form-control mb-4"
            aria-describedby="password"
            v-model="password"
            minlength="8"
            required
          />
        </div>
        <WhiteLabel class="mb-4 offset-8 col-4 col-w-full">
          <button
            class="btn btn-md btn-orange col-12"
            @click="confirmTransference"
          >
            Transferir
          </button>
        </WhiteLabel>
      </div>
    </div>
  </div>
</template>

<script>
import ModalBase from '@/components/shared/ModalBase';
import WhiteLabel from '@/components/shared/WhiteLabel';
import AppModalSuccessTransference from '@/components/account/transference/modal/ModalSuccessTransference';
import AuthCodeModalSection from '@/components/shared/modal/AuthCodeModalSection';

import PaymentAccountsApiV1 from '@/services/v1/PaymentAccountsApi';
import PaymentAccountsApiV2 from '@/services/v2/PaymentAccountsApiV2';
import AccountApi from '@/services/AccountApi';

import Alert from '@/models/Alert';
import { Presets } from '@/models/Presets';

import HolderTransferError from '@/errors/HolderTransferError';
import SplitterTransferError from '@/errors/SplitterTransferError';

export default {
  name: 'AppModalRequestTransfer',

  components: {
    WhiteLabel,
    AuthCodeModalSection,
  },

  props: {
    id: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      required: true,
    },
    typeTransfer: {
      type: Number,
      required: true,
    },
    fee: {
      type: Number,
      required: true,
    },
    recipient: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data: () => ({
    password: null,
    modalSuccessTransference:
      'modalSuccessTransference' + Math.random().toString('16').slice(2, 8),
    idInput: 'password' + Math.random().toString('16').slice(2, 8),
    idInput2: 'password' + Math.random().toString('16').slice(2, 8),
    document: '',
    authCodeSubSection: 'destination',
    subSection: 'authCode',
    preset: new Presets(),
  }),

  async mounted() {
    await this.presets();
    this.$bus.$on('cleanInput', (result) => {
      this.cleanInput();
    });
  },

  methods: {
    cleanInput() {
      this.password = '';
    },

    async confirmTransference() {
      var alert = new Alert();
      alert.title = 'Ops';
      alert.confirmButtonText = 'OK';
      if (this.password === null || this.password.length < 6) {
        alert.error = 'Senha deve ter no mínimo 6 caracteres.';
        this.$bus.$emit('alert-error', alert);
      } else {
        const api = new AccountApi();
        this.$bus.$emit('spinner-run');
        const response = await api.checkPassword(this.password);
        this.$bus.$emit('spinner-stop');

        if (response.status === 422) {
          alert.error = 'Senha inválida.';
          this.$bus.$emit('alert-error', alert);
        } else {
          this.sendTransference();
        }
      }
    },

    async sendTransference(authCode = null) {
      let response = null;
      const apiV1 = new PaymentAccountsApiV1();
      const apiV2 = new PaymentAccountsApiV2();
      this.$bus.$emit('spinner-run');
      switch (this.typeTransfer) {
        case 0:
          response = await apiV1.requestTransferOther(
            this.value,
            this.recipient.id
          );
          break;

        case 0.5:
          response = await apiV2.requestTransferBetweenHolders(
            this.value,
            this.recipient.bankAccount.taxDocument,
            authCode
          );
          break;

        case 1:
          response = await apiV1.requestTransferOwn(this.value);
          break;

        default:
          break;
      }
      var alert = new Alert();

      if (response && response.status === 400) {
        this.$bus.$emit('spinner-stop');
        alert.title = 'Ops';
        alert.error = 'Não foi possível realizar a transferência';
        alert.confirmButtonText = 'OK';
        this.$bus.$emit('alert-error', alert);
      } else if (response && response.status === 422) {
        this.$bus.$emit('spinner-stop');

        var modelError;

        this.typeTransfer == 0.5
          ? (modelError = new HolderTransferError(response.data))
          : (modelError = new SplitterTransferError(response.data));

        alert.title = 'Ops';
        alert.error = `Transferência reprovada. ${modelError.getMessage()}`;

        alert.confirmButtonText = 'OK';
        this.$bus.$emit('alert-error', alert);
      } else if (
        response.status === 200 &&
        this.typeTransfer === 0.5 &&
        response.data.cashoutRequestNotMade.count != 0
      ) {
        this.$bus.$emit('spinner-stop');
        alert.title = 'Ops';
        alert.error =
          'Não foi possível realizar a transferência, entrar em contato com o responsável da plataforma.';
        alert.confirmButtonText = 'OK';
        this.$bus.$emit('alert-error', alert);
      } else {
        this.$bus.$emit('spinner-stop');
        this.$bus.$emit('updateListTransference');
        this.password = '';
        if (this.typeTransfer === 1) this.$emit('getConfirm', 1.3);
        else {
          this.$emit('transfer-other', {
            fee:
              this.typeTransfer === 0.5
                ? response.data.cashoutRequestMade.cashoutRequests[0].fee
                : response.data.bankTransferFee,
            control: 2.4,
          });
        }
      }
    },

    authCodeNextSection() {
      this.authCodeSubSection = 'code';
    },

    async presets() {
      const api = new AccountApi();
      this.preset = await api.presets();
    },
  },
};
</script>

<style lang="scss" scoped>
.request-transfer {
  display: flex;
  flex-direction: column;
  padding: 13rem 1rem;

  .password-label {
    font-size: 1.3rem;
    font-weight: bold;
  }

  .input-password {
    width: 100%;
  }
}
</style>
