import CustomApiError from './CustomError';

export default class HolderTransferError extends CustomApiError {
  toDictionary() {
    return {
      DOESNT_HAVE_MINIMUM_AMOUNT:
        'O titular não possui o valor mínimo para transferência.',
      BALANCE_BLOCKED: 'O saldo está bloqueado.',
      REQUESTER_PAYMENT_ACCOUNT_NOT_FOUND:
        'Conta de pagamento para transferência não encontrada.',
      CASHOUT_REQUEST_AMOUNT_BIGGER_THAN_AVAILABLE_BALANCE:
        'Saldo insuficiente para essa transferência.',
      RECIPIENTS_NOT_FOUND: 'Destinatário não encontrado.',
      '2FA_TOKEN_INVALID': 'Token 2FA inválido.',
      AUTH_CODE_NOT_FOUND: 'Código de autenticação não encontrado.',
    };
  }
}
