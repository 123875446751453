<template>
  <div class="details-bank pl-2">
    <div class="info-bank">
      <em class="icon bank-icon gray-2 ml-1 mr-2"></em>
      <div class="data">
        <h1 class="text-uppercase label mb-2">Destino</h1>
        <div v-if="getBankAccount !== null || pBankData !== undefined">
          <h1 class="name-bank label">
            <b v-if="pBankData !== undefined">{{ pBankData.bankName }}</b>
            <b v-else>{{ getBankAccount.bankName }}</b>
          </h1>
          <h1 class="agency label" v-if="pBankData !== undefined">
            <b class="text-uppercase">ag</b>
            {{ pBankData.bankBranchNumber }}
          </h1>
          <h1 class="agency label" v-else>
            <b class="text-uppercase">ag</b>
            {{ getBankAccount.bankBranchNumber }}
          </h1>
          <div class="account">
            <div>
              <h1 class="label" v-if="pBankData !== undefined">
                <b class="text-uppercase">cc</b>
                {{ pBankData.accountNumber }}
              </h1>
              <h1 class="label" v-else>
                <b class="text-uppercase">cc</b>
                {{ getBankAccount.accountNumber }}
              </h1>
            </div>
            <div>
              <h1
                class="label px-1"
                v-if="pBankData !== undefined && pBankData.variation"
              >
                <b class="text-uppercase label">var</b>
                {{ pBankData.variation }}
              </h1>
              <h1 class="label" v-else-if="getBankAccount.variation">
                <b class="text-uppercase">var</b>
                {{ getBankAccount.variation }}
              </h1>
            </div>
          </div>
          <div>
            <h1
              class="name-user label"
              v-if="pBankData !== undefined && pBankData.holderFullName"
            >
              {{ pBankData.holderFullName }}
            </h1>
            <h1 class="name-user label" v-else>{{ holderName }}</h1>
          </div>
          <div>
            <h1
              class="cpf label"
              v-if="pBankData !== undefined && pBankData.holderTaxDocument"
            >
              {{ pBankData.holderTaxDocument }}
            </h1>
            <h1 class="cpf label" v-else>{{ holderTaxDocument }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountApi from '@/services/AccountApi';
export default {
  name: 'AppDetailsBank',
  props: {
    pBankData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    holderName: {
      type: String,
      default: '',
    },
    holderTaxDocument: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      bankData: 0,
    };
  },
  async mounted() {
    this.$bus.$emit('spinner-run');
    if (this.pBankData === undefined) await this.getBankDataApi();
    this.$bus.$emit('spinner-stop');
  },
  methods: {
    async getBankDataApi() {
      const api = new AccountApi();
      this.bankData = await api.getBankData();
    },
  },
  computed: {
    getBankAccount() {
      if (!this.bankData.bankAccount) return { bankAccount: '' };
      return this.bankData.bankAccount;
    },
  },
};
</script>

<style lang="scss" scoped>
.details-bank {
  background-color: #f5f5f5;
  width: 100%;
  margin-bottom: 0;

  .info-bank {
    display: flex;
    align-items: flex-start;

    .icon.bank {
      height: 1.5rem;
    }

    .data {
      .label {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #7d8287;
        margin-bottom: 3px;
      }

      .account {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>
