import { withParams } from 'vuelidate/lib';

const bankAccountNumber = withParams({ type: 'bankAccountNumber' }, value => {
  if (!value) return true;

  var regex = new RegExp('^[0-9]{3,13}-[0-9a-zA-Z]{1,2}$');
  return regex.test(value);
});

export { bankAccountNumber };
