var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-transfer-base"},[_c('ModalBase',{attrs:{"hasFooter":false,"title":_vm.title,"id":_vm.id,"btnBackgroudGray":_vm.btnBackgroudGray,"size":_vm.control === 1 || _vm.control === 2.1
        ? 'how-much-transfer'
        : _vm.control === 0
        ? 'preferences-account-size'
        : 'transference-size'}},[(_vm.control === 0)?_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('AppModalSelectDestination',{attrs:{"balance":_vm.balance.currentBalance},on:{"clicked":_vm.changeControl}})],1):_vm._e(),(_vm.control === 1)?_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('AppModalHowMuchTransfer',{attrs:{"typeTransfer":_vm.typeTransfer.ownAccount},on:{"getValue":_vm.setValue}})],1):_vm._e(),(_vm.control === 1.1)?_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('AppModalConfirmTransferData',{attrs:{"typeTransfer":_vm.typeTransfer.ownAccount,"value":_vm.value,"fee":_vm.balance.transferFee,"splitter":_vm.favored},on:{"getConfirm":_vm.changeControl}})],1):_vm._e(),(_vm.control === 1.2)?_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('AppModalRequestTransfer',{attrs:{"value":_vm.value,"typeTransfer":_vm.typeTransfer.ownAccount,"fee":_vm.balance.transferFee,"splitter":_vm.favored},on:{"getConfirm":_vm.changeControl}})],1):_vm._e(),(_vm.control === 1.3)?_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('AppModalSuccessTransference',{attrs:{"value":_vm.value,"typeTransfer":_vm.typeTransfer.ownAccount,"fee":_vm.balance.transferFee,"destination":_vm.favored}})],1):_vm._e(),(_vm.control === 1.7)?_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('AppModalSellerOrSplitter',{on:{"splitters-selected":function($event){_vm.changeControl(2);
          _vm.isSellers = false;},"sellers-selected":function($event){_vm.changeControl(2);
          _vm.isSellers = true;}}})],1):_vm._e(),(_vm.control === 2)?_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('AppModalWhoIstheFavored',{attrs:{"typeTransfer":_vm.typeTransfer.otherSplitterAccount,"isSellers":_vm.isSellers},on:{"updateSplitter":_vm.updateFavored}})],1):_vm._e(),(_vm.control === 2.1)?_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('AppModalHowMuchTransfer',{attrs:{"typeTransfer":_vm.typeTransfer.otherSplitterAccount,"splitter":_vm.favored},on:{"getValue":_vm.setValue}})],1):_vm._e(),(_vm.control === 2.2)?_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('AppModalConfirmTransferData',{attrs:{"typeTransfer":_vm.typeTransfer.otherSplitterAccount,"value":_vm.value,"fee":_vm.balance.transferFee,"splitter":_vm.favored},on:{"getConfirm":_vm.changeControl}})],1):_vm._e(),(_vm.control === 2.3)?_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('AppModalRequestTransfer',{attrs:{"value":_vm.value,"typeTransfer":_vm.isSellers
            ? _vm.typeTransfer.otherSellerAccount
            : _vm.typeTransfer.otherSplitterAccount,"fee":_vm.balance.transferFee,"recipient":_vm.favored},on:{"transfer-other":function($event){return _vm.setFee($event)}}})],1):_vm._e(),(_vm.control === 2.4)?_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('AppModalSuccessTransference',{attrs:{"value":_vm.value,"typeTransfer":_vm.typeTransfer.otherSplitterAccount,"fee":_vm.fee,"destination":_vm.favored}})],1):_vm._e(),(_vm.control >= 3 && _vm.control < 4)?_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('ModalTED',{attrs:{"changeControl":_vm.changeControl,"viewControl":_vm.control}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }